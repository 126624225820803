@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}
.HeadingText {
  font-size: 65px;
  font-family: 'Roboto Slab', serif;
  z-index: 2;
}

/* Underlined */
.underline {
  text-decoration: underline;
}
.notUnderlined {
  text-decoration: none;
}

/* Opening sliding text */
div.slide-right {
  width:100%;
  overflow:hidden;
}
div.slide-right p {
  animation: 2s slide-right;
  margin: 0px;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
    width: 300%; 
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

/* NavBar changes */
.navBr {
  z-index: 2;
}

/* Cards */
.PageCard {
  margin-top: 40px 0px!important;
  font-size: 20px;
  background-image: url(/public/images/bbblurry.svg);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: rgb(20, 20, 20)!important; */
  z-index: 2;
  animation: rotate 1s ease-in-out;
}

.technologies {
  margin: 15px;
  font-weight: 700;
}
.highlighted-text {
  background-color: rgb(136, 136, 32);
  border-radius: 15px;
  padding: 15px;
}

@keyframes rotate {
  0% {
    opacity: 0;
    translate: 0 16px;
    }
    100% {
    opacity: 1;
    translate: 0px;
    }
}

.Card2 {
  position: relative; top: 50px
}
.lastCard {
  margin-bottom: 275px;
}
.pricingOptionCard {
  background-color: rgb(30, 30, 34)!important;
  margin-top: 40px 0px!important;
  font-size: 20px;
}
.BasicPageCard {
  margin-bottom: 100px!important;
}
/*Card of me*/
.iCard {
  width: 200px;
  height: 291px;
  margin: 0 auto;
  background: center no-repeat;
  background-size: cover;
  border-radius: 10px;
  transform: 
  rotate(20deg)
  skew(-10deg, -10deg);
  box-shadow: 25px 10px rgb(217 220 242 / 75%), 
  50px 20px rgb(44 217 255 / 50%), 
  75px 30px rgb(126 255 178 / 45%);
  transition: 
    translate 0.25s,
}
.iCard:hover {
  translate: 0 -20px;
}
.Pcard {
  transition: 
    translate 0.25s,
}
.Pcard:hover {
  translate: 0 -20px;
}
/*scroll down*/
.mouse {
  display: inline-flex;
  width: 25px;
  height: 50px; 
  border: 2px solid #6d6d6d;
  border-radius: 20px;
}
.mouse-wheel {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #6d6d6d;
  border-radius: 50%;
  margin: auto;
  animation: movewheel 1.25s
  linear infinite;
}
@keyframes movewheel {
  0% {
  opacity: 0;
  translate: 0 16px;
  }
  100% {
  opacity: 1;
  translate: 0 -16px;
  }
}
.scrollD {
  font-size: 20px;
}

/* Rows */
.homePageRowOne {
  margin: 50px 0px!important;
}
.homePageRowTwo {
  padding: 50px 0px;
}
.pricingPageRowOne {
  margin: 50px 0px!important;
}
.BasicFirstRow {
  margin: 50px 0px!important;
}
.contactFirstPage {
  margin: 50px 0px!important;
}
.contactFirstRow {
  margin-top: 120px!important;
}

/* Fill-Ins */
.secondSlideFillIn {
  height: 400px;
}
.thirdSlideFillIn {
  height: 120px;
}


/* Second Slide-in */
.StartingAt {
  font-size: 35px;
  color: white;
  text-decoration: underline;
}
.StartingAtTwo {
  font-size: 35px;
  color: black;
  text-decoration: underline;
}
.fifthteenD {
  font-size: 120px;
  position: relative; top: -35px;
  padding-bottom: 55px;
  color: white;
  text-decoration: underline;
}
.fifthteenDTwo {
  font-size: 120px;
  position: relative; top: -35px;
  padding-bottom: 55px;
  color: black;
  text-decoration: underline;
}
.git:hover p {
  background: -webkit-linear-gradient(#eee, #0c4b24);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.git:hover {
  text-decoration: underline!important;
  color: black;
}
.gitTwo:hover p {
  background: -webkit-linear-gradient(#eee, #0c4b24);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gitTwo:hover {
  text-decoration: underline!important;
  color: white;
}
.keepLinkWhite {
  color: white;
}
.keepLinkWhite:hover {
  color: rgb(199, 199, 199);
}
/* .for12months {
  font-size: 25px;
  position: relative; top: -65px;
  padding-bottom: 55px;
} */

/* React Out card links */
.ROLink {
  /* color: rgb(83, 90, 76)!important; */
  padding: 2px 5px 7px 5px;
  border-radius: 10px;
}
.ROLink:hover {
  /* color:white!important; */
  background-color: rgb(136, 136, 136);
}

/* Footer */
footer {
  font-size: 20px;
  padding: 20px;
}
.FLink {
  color: rgb(154, 211, 133);
}
.FLink:hover {
  color: rgb(86, 86, 86);
}

/* Pricing Page */
.pricingFirstSlide {
  font-size: 35px;
  font-family: 'Roboto Slab', serif;
}

.btnToLink {
  text-decoration: none!important;
}
/* <head>
  <style type="text/css">
    .body {
    background-color: black;
    color: white;
    padding: 20px 10px;
    }
    .para {
    background-color: rgb(87, 146, 65);
    padding: 10px 5px;
    }
  </style>
</head>
<div class="body">
  <h1>Hello there!</h1>
  <div class="para">
  	<p>My name is Rylan and I am a web developer! I love learning more about technology!</p>
  	<p>I am currently available for hire as a web developer (I'm looking at you floatplane!) But if your just a person who needs a portfolio site or you need 	something for your company, feel free to find out more details about me at <a href="https://rylanworkman.com" class="link">RylanWorkman.com</a>		</p>
  </div>
</div> */
.btnToLink:hover {
  background-color: rgb(19, 19, 19)!important;
  text-decoration: none;
}
.choices {
  margin-top: 120px!important;
}

/* About Page */
.links {
  display: flex;
  justify-content: space-evenly;
  font-size: 24px;
  padding: 15px;
}

.edu {
  padding-top: 25px;
}

.eduPhoto {
  aspect-ratio: 3/2;
}

.AbtFtr {
  padding: 40px;
}

.links a {
  text-decoration: none;
  color: rgb(141, 210, 141);
}

.links a:hover {
  color: white;
}
 
/* Extra Spacing */
.PHeight {
  height: 50px;
}

.planHeading:hover {
  color: rgb(83, 90, 76);
}

/* Basic Site Page */
.BasicFirstSlide {
  font-size: 60px;
  font-family: 'Roboto Slab', serif;
}

/* Contact page */
.contactFirstSlide {
  font-size: 35px;
  font-family: 'Roboto Slab', serif;
}
.contactLink:hover {
  color: rgb(168, 237, 131);
}
.contactCard {
  margin-bottom: 20px;
}
.pricingOptionCard {
  margin-bottom: 20px;
}
.getInContactBtn {
  padding: 15px 100px 10px 100px!important;
}

/* modal */
.modal-body {
  color: black;
}
.modal-header {
  color: black;
}

.youtube-video {
  aspect-ratio: 16/9;
  margin: 12px 0px;
}
.youtube-video-card {
  margin: 25px 0px;
}

/* Hover effect from hyperplexed on YT */
/* #blob {
  background-color: white;
  height: 500px;
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 50%;
  z-index: 2;
  filter: blur(250px);
  background: linear-gradient(
    to right,
    rgb(158, 204, 151),
    rgb(10, 50, 10)
  );
  animation: rotate2 20s infinite;
}
@keyframes rotate2 {
  from {
    rotate:0deg;
  }

  50% {
    scale: 1 1.2;
  }
  to {
    rotate: 360deg;
  }
} */


/* Profile */
.profileLink {
  color: rgb(154, 211, 133);
}
.profileLink:hover {
  color: rgb(86, 86, 86);
}



/* 768 or lower */
@media (max-width: 767px) {
  .Card2 {
    position: relative; top: -110px
  }
  .Pcard1 {
    margin-bottom: 40px;
  }
}

/* 768 or higher */
@media (min-width: 768px) {
  .HeadingText {
    font-size: 65px;
    padding-top: 100px;
  }
  .fifthteenD {
    font-size: 100px;
  }
  .fifthteenDTwo {
    font-size: 100px;
  }
  .homePageRowOne {
    margin: 100px 0px!important;
  }
  .homePageRowTwo {
    margin: 400px 0px!important;
  }
  .pricingFirstSlide {
    font-size: 65px;
  }
  .contactSlideText {
    font-size: 65px;
  }
  .scroll {
    margin-top: 200px;
  }
}

/* 992 or higher */
@media (min-width: 992px) {
  .HeadingText {
    font-size: 85px;
    padding-top: 50px;
  }
  .fifthteenD {
    font-size: 150px;
  }
  .fifthteenDTwo {
    font-size: 150px;
  }
  .for12months {
    font-size: 35px;
  }

}

/* 
slide in from right example
<style>
div.slide-left {
  width:100%;
  overflow:hidden;
}
div.slide-left p {
  animation: slide-left 10s;
}

@keyframes slide-left {
  from {
    margin-left: 100%;
    width: 300%; 
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>

<div class="slide-left">
<p>Never fight an inanimate object.</p>
</div> */